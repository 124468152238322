/* eslint-disable no-plusplus */
/* eslint-disable radix */
import { includes, some } from 'lodash';
import { message } from 'antd';
import CryptoJS from 'crypto-js';
import i18next from 'i18next';

const salt = process.env.REACT_APP_SECRET_PASSWORD;

export const forceLogout = (msg = false) => {
  if (msg) {
    message.error(msg);
  }
  localStorage.clear();
  setTimeout(() => {
    window.location.replace('/');
  }, 1000);
};

export const crypt = (data) => data && CryptoJS.DES.encrypt(data, salt).toString();

export const decrypt = (data) => {
  try {
    return data && CryptoJS.DES.decrypt(data, salt).toString(CryptoJS.enc.Utf8);
  } catch (error) {
    forceLogout(i18next.t('messages:invalidData'));
    return false;
  }
};
export const filterString = (a, b) => {
  if (a > b) {
    return 1;
  }
  if (a < b) {
    return -1;
  }
  return 0;
};

export function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
  return reader;
}

export function normalizeString(str) {
  const strAccents = str.split('');
  const strAccentsOut = [];

  const strAccentsLen = strAccents.length;

  // eslint-disable-next-line no-plusplus
  for (let y = 0; y < strAccentsLen; y++) {
    if (!some(['ä', 'ü', 'ö'], (el) => includes(strAccents[y], el))) {
      strAccentsOut[y] = strAccents[y].normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    } else {
      strAccentsOut[y] = strAccents[y];
    }
  }

  const newString = strAccentsOut.join('').replace('ß', 'ss');
  return newString;
}

export function formatPhone(phoneNumber) {
  const phone = phoneNumber.replace(/[|&;$%@"<>()+,]/g, '');
  let output;

  if (!phone) {
    return null;
  }
  const realPhone = phone.slice(2);
  const phoneLength = realPhone.length;

  if (phoneLength === 9) {
    output = [`(${phone.slice(0, 2)}) ${realPhone.slice(0, 5)}-${realPhone.slice(phoneLength - 4)}`];
  } else {
    output = [`(${phone.slice(0, 2)}) ${realPhone.slice(0, 4)}-${realPhone.slice(phoneLength - 4)}`];
  }

  return output;
}

export function handleAbreviate(name) {
  const [nome, ...sobrenomes] = name.split(' ');

  const abreviaturas = sobrenomes.reduce((arr, str) => {
    const letraGrande = str.match(/[A-ZÖÄÅÀÁÂÃÌÍÒÓÉÊÚ]/);
    if (!letraGrande) return arr;
    return arr.concat(`${letraGrande[0]}.`);
  }, []);

  return [nome, ...abreviaturas].join(' ');
}

export function dynamicFormatPhone(v) {
  v = v.replace(/\D/g, '');
  v = v.replace(/^(\d{2})(\d)/g, '($1) $2');
  v = v.replace(/(\d)(\d{4})$/, '$1-$2');
  return v;
}

export function dynamicCost(valor) {
  const v = `${(valor.replace(/\D/g, '') / 100).toFixed(2)}`.split('.');

  const m = v[0]
    .split('')
    .reverse()
    .join('')
    .match(/.{1,3}/g);

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < m.length; i++) m[i] = `${m[i].split('').reverse().join('')}.`;

  const r = m.reverse().join('');

  return `${r.substring(0, r.lastIndexOf('.'))},${v[1]}`;
}

export function validateCpf(cpf) {
  let soma = 0;
  let resto;
  if (typeof cpf !== 'string') return false;
  cpf = cpf.replace(/[\s.-]*/gim, '');
  if (
    !cpf ||
    cpf.length !== 11 ||
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  ) {
    return false;
  }
  for (let i = 1; i <= 9; i++) soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.substring(9, 10))) return false;
  soma = 0;
  for (let i = 1; i <= 10; i++) soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.substring(10, 11))) return false;
  return true;
}

export function checkIfColorIsDarken(color) {
  const c = color.substring(1); // strip #
  const rgb = parseInt(c, 16); // convert rrggbb to decimal
  const r = rgb > 16 && 0xff; // extract red
  const g = rgb > 8 && 0xff; // extract green
  const b = rgb > 0 && 0xff; // extract blue

  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

  if (luma <= 55) {
    return false;
  }
  return true;
}

export function getColorByAbstractStatus(status, is_evaluator) {
  if (is_evaluator) {
    switch (status) {
      case 'Aguardando correção':
        return 'orange';
      case 'Pendente':
        return 'green';
      case 'Reprovado':
        return 'geekblue';

      default:
        return 'blue';
    }
  }

  switch (status) {
    case 'Aguardando correção':
      return 'red';
    case 'Pendente':
      return 'orange';
    case 'Reprovado':
      return 'magenta';

    default:
      return 'green';
  }
}

export function capitalize(s) {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
}
