/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card, Tag, Spin, message } from 'antd';

import { Formik } from 'formik';
import { Form, Select } from 'formik-antd';

import { FaEye, FaScroll, FaCoins, FaPaperclip } from 'react-icons/fa';
import DefaultLayout from '~/pages/_layouts/full';
import PageTitle from '~/components/PageTitle';
import Box from '~/components/Box';
import Button from '~/components/Button';
import { Table } from '~/components/Table';
import FormControl from '~/components/Form/FormControl';

import checkout from '~/services/checkout';
import api from '~/services/api';
import errorHandler from '~/Utils/errorHandler';
import { loadPagseguroScripts } from '~/Utils/pagseguro';
import { capitalize } from '~/Utils';
import { Title, GlobalStyle } from './styles';

export default function Course() {
  const { t } = useTranslation();
  const [recordData, setRecordData] = useState([]);
  const [loading, setLoading] = useState();
  const [congressesOptions, setCongressesOptions] = useState([]);
  const { congressId } = useParams();
  const history = useHistory();

  const fetchRecords = async (id, setErrors) => {
    setLoading(true);
    try {
      const response = await api.get('/courses', {
        params: {
          congress_id: id,
        },
      });

      if (response.data.length === 0) {
        setLoading(false);
        message.info('Nenhum curso encontrado!');
      }

      setRecordData(response.data);

      if (!document.getElementById('pgScript')) {
        await loadPagseguroScripts();
      }

      setErrors({});
    } catch (error) {
      setErrors(errorHandler(error));
    }
    setLoading(false);
  };

  const handleCheckoutCourse = async (id) => {
    setLoading(true);
    const data = await checkout('course', id);

    if (data) {
      // eslint-disable-next-line no-undef
      if (typeof PagSeguroLightbox !== 'function' || PagSeguroLightbox === undefined) {
        window.location.replace(data.url);
      }

      // eslint-disable-next-line no-undef
      const lightbox = PagSeguroLightbox(data.code);
      if (!lightbox) {
        window.location.replace(data.url);
      }
    }

    setLoading(false);
  };

  const handleNewSubscription = (slug) => {
    history.push(`/inscricao/curso/${slug}`);
  };

  const fetchCongresses = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('congresses/paid');
      setCongressesOptions(data);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchCongresses();
  }, []);

  const tableColumns = [
    {
      // title: 'Inscrições Abertas',
      render: (text, record) => (
        <Card title={<Title>{capitalize(record.description)}</Title>}>
          <div
            style={{
              display: 'flex',
              float: 'none',
              justifyContent: 'flex-start',
              // justifyContent: 'flex-end',
            }}
          >
            {/* {record.subscription && !record.subscription.paid_out && !record.subscription.tax_exempt && (
              <Button onClick={() => handleCheckoutCourse(record.id)} color="primary">
                <FaCoins style={{ fontSize: '14px' }} /> Pagar
              </Button>
            )} */}
            {record.subscription && !record.subscription.paid_out && !record.subscription.tax_exempt && (
              <Button
                // onClick={() => handleCheckoutCongress(record.id)}
                onClick={() => {
                  history.push({
                    pathname: '/pagamento',
                    state: { type: 'congress', typeId: record.id },
                  });
                }}
                color="primary"
              >
                <FaCoins style={{ fontSize: '14px' }} /> Pagar
              </Button>
            )}
            <Button onClick={() => handleNewSubscription(record.slug)} color="primary" style={{ marginLeft: '0.5rem' }}>
              {record.subscription ||
              (record.subscription && (record.subscription.paid_out || record.subscription.tax_exempt)) ? (
                <FaEye />
              ) : (
                <FaScroll />
              )}
              {record.subscription ||
              (record.subscription && (record.subscription.paid_out || record.subscription.tax_exempt))
                ? 'Visualizar'
                : 'Inscrição'}
            </Button>
          </div>
          <Tag color="blue">
            Até <b>{new Date(record.date_time).toLocaleString()}</b>
          </Tag>
          {record.professor && (
            <Tag color="geekblue">
              Por <b>{record.professor}</b>
            </Tag>
          )}
          {record.subscription && (record.subscription.paid_out || record.subscription.tax_exempt) ? (
            <Tag color="green">{record.subscription.paid_out ? 'Pago' : 'Isento'}</Tag>
          ) : (
            <Tag color={record.subscription ? 'red' : 'gold'}>
              {record.subscription ? 'Aguardando pagamento' : 'Inscrição não realizada'}
            </Tag>
          )}
          <p style={{ marginTop: '0.5rem' }}>{record.info}</p>

          {record.url && (
            <Button onClick={() => window.open(record.url)}>
              <FaPaperclip /> Mais informações
            </Button>
          )}
        </Card>
      ),
    },
  ];

  return (
    <DefaultLayout>
      <GlobalStyle />
      <PageTitle
        title={t('screens:courses.title')}
        subtitle={recordData && recordData.length > 0 && t('screens:subscriptions.openedTitle')}
      />
      <Box>
        <Formik validateOnBlur={false} validateOnChange={false} initialValues={{ congress_id: '' }} enableReinitialize>
          {({ isSubmitting, errors, setErrors }) => (
            <Spin spinning={loading || isSubmitting}>
              <Form>
                <FormControl
                  cols={{ xs: 12, xl: 8, sm: 24 }}
                  error={errors.congress_id}
                  field="congress_id"
                  label={t('fields:congress_id')}
                  required
                >
                  <Select
                    name="congress_id"
                    allowClear
                    optionFilterProp="children"
                    onChange={(value) => fetchRecords(value, setErrors)}
                  >
                    {congressesOptions &&
                      congressesOptions.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.description}
                        </Select.Option>
                      ))}
                  </Select>
                </FormControl>
              </Form>
            </Spin>
          )}
        </Formik>
      </Box>
      {recordData && recordData.length > 0 && (
        <Box>
          <div className="courses-list">
            <Table columns={tableColumns} loading={loading} dataSource={recordData} rowKey="id" />
          </div>
        </Box>
      )}
    </DefaultLayout>
  );
}
