import React, { useEffect, useState } from 'react';
import PageTitle from '~/components/PageTitle';
import DefaultLayout from '~/pages/_layouts/full';
import { useParams, useHistory } from 'react-router-dom';
import api from '~/services/api';
import errorHandler from '~/Utils/errorHandler';
import Box from '~/components/Box';
import { Card, Table, Typography, message } from 'antd';
import { FaCoins, FaScroll } from 'react-icons/fa';
import Button from '~/components/Button';
import { Title } from '../Congress/styles';
import { GlobalStyle } from '../Register/styles';

export default function NewCourse() {
  const { id } = useParams();
  const [congress, setCongress] = useState({});
  const [courses, setCourse] = useState([]);
  const [loading, setLoading] = useState();
  const [me, setMe] = useState([]);
  const history = useHistory();
  const [subscription, setSubscription] = useState({});

  const fetchRecords = async () => {
    setLoading(true);
    try {
      const responseCongress = await api.get(`/congresses/${id}`);
      const congressFetch = responseCongress.data;
      const responseCourse = await api.get(`/courses/by-congress/${id}`);
      const coursesFetch = responseCourse.data;
      const responseMe = await api.get('/me');
      const meFetch = responseMe.data;
      const responseSubsCourse = await api.get(`/subscriptions/courses/${id}`);
      const subsCourseFetch = responseSubsCourse.data;
      const responseSubsCongress = await api.get(`/subscriptions/congress/${id}`);
      const subsCongressFetch = responseSubsCongress.data;

      setCongress(congressFetch);
      setCourse(coursesFetch);
      setMe(meFetch);
      setSubscription(subsCongressFetch);

      // Verificar inscrições nos cursos
      const updatedCourses = coursesFetch.map((course) => {
        // Verificar se há uma inscrição correspondente no subsCourse
        const isSubscribed = subsCourseFetch.some(
          (subsCourse) => subsCourse.course.id === course.id && subsCourse.person.id === meFetch.id
        );
        const isPaid = subsCourseFetch.some(
          (subsCourse) =>
            subsCourse.course.id === course.id &&
            subsCourse.person.id === meFetch.id &&
            (subsCourse.paid_out === true || subsCourse.tax_exempt === true)
        );
        const isFree = subsCourseFetch.some(
          (subsCourse) =>
            subsCourse.course.id === course.id && subsCourse.person.id === meFetch.id && subsCourse.free_course
        );

        // Adicionar as propriedades ao curso com base nas verificações
        return { ...course, subscribed: isSubscribed, paid_out: isPaid, isFree };
      });

      setCourse(updatedCourses);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleSubscribe = async (course) => {
    setLoading(true);
    try {
      subscription.course_id = course.id;
      subscription.paid_out = false;
      subscription.course = course;
      subscription.congress_id = subscription.congress.id;

      if (course.free_for_associated === true || course.free === true) {
        subscription.tax_exempt = true;
      } else {
        subscription.tax_exempt = false;
      }

      await api.post('/subscriptions', {
        ...subscription,
      });

      message.success('Inscrição realizada com sucesso!');
      fetchRecords();
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  console.log(courses);
  console.log('/me: ', me);
  const tableColumns = [
    {
      render: (course) => (
        <>
          {course.subscribed === true && (
            <>
              <Title style={{ color: '#a4c75b' }}>Inscrito</Title>
              <Card
                size="small"
                title={<strong style={{ color: '#a4c75b' }}>{course.description}</strong>}
                style={{ width: '100%' }}
              >
                <div style={{ display: 'flex' }}>
                  <Typography.Title key={course.id} level={5} style={{ margin: 0, fontWeight: 'normal' }}>
                    {course.date_time && (
                      <p>
                        <strong style={{ color: '#a4c75b' }}>Data/Hora:</strong> {course.date_time}
                      </p>
                    )}
                    {course.professor && (
                      <p>
                        <strong style={{ color: '#a4c75b' }}>Professor:</strong> {course.professor}
                      </p>
                    )}
                    {course.info && (
                      <p>
                        <strong style={{ color: '#a4c75b' }}>Informações:</strong> {course.info}
                      </p>
                    )}
                    {course.description && (
                      <p>
                        <strong style={{ color: '#a4c75b' }}>Descrição:</strong> {course.description}
                      </p>
                    )}
                  </Typography.Title>
                </div>
                <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'end' }}>
                  {course.bank_account_id !== null &&
                  course.paid_out === false &&
                  course.free_for_associated === false ? (
                    <Button
                      onClick={() => {
                        const type = 'course';
                        const typeId = course.id;
                        history.push({
                          pathname: '/pagamento',
                          state: { type, typeId },
                        });
                      }}
                      color="danger"
                    >
                      <FaCoins style={{ fontSize: '14px' }} /> Pagar agora
                    </Button>
                  ) : (
                    <Button color="ghost" style={{ marginLeft: '0.5rem' }}>
                      <FaScroll /> Inscrito
                    </Button>
                  )}
                </div>
              </Card>
            </>
          )}
          {course.subscribed === false && (
            <>
              <Title style={{ color: '#a4c75b' }}>Não inscrito</Title>
              <Card
                size="small"
                title={<strong style={{ color: '#a4c75b' }}>{course.description}</strong>}
                style={{ width: '100%' }}
              >
                <div style={{ display: 'flex' }}>
                  <Typography.Title key={course.id} level={5} style={{ margin: 0, fontWeight: 'normal' }}>
                    {course.date_time && (
                      <p>
                        <strong style={{ color: '#a4c75b' }}>Data/Hora:</strong> {course.date_time}
                      </p>
                    )}
                    {course.professor && (
                      <p>
                        <strong style={{ color: '#a4c75b' }}>Professor:</strong> {course.professor}
                      </p>
                    )}
                    {course.info && (
                      <p>
                        <strong style={{ color: '#a4c75b' }}>Informações:</strong> {course.info}
                      </p>
                    )}
                    {course.description && (
                      <p>
                        <strong style={{ color: '#a4c75b' }}>Descrição:</strong> {course.description}
                      </p>
                    )}
                  </Typography.Title>
                </div>
                <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'end' }}>
                  <Button onClick={() => handleSubscribe(course)} color="primary" style={{ marginLeft: '0.5rem' }}>
                    <FaScroll /> Inscrição
                  </Button>
                </div>
              </Card>
            </>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    fetchRecords();
  }, []);

  console.log(congress, courses, loading);
  return (
    <DefaultLayout>
      <GlobalStyle />
      <>
        <PageTitle title={congress && congress.length > 0 ? `Cursos - ${congress[0].description}` : 'Cursos'} />
        <Box>
          <div className="congresses-list">
            <Table columns={tableColumns} loading={loading} dataSource={courses} />
          </div>
        </Box>
      </>
    </DefaultLayout>
  );
}
